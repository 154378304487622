import React from 'react';
//import Layout from '../components/layout';
import SEO from '../components/seo';
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact';
import Pageheader from '../Utils/pageheader';

const DonatePage = () => {
    return (
        <>
            <SEO title="Donate" />
            <MDBContainer className="mt-4">
                <h3 className="title font-weight-bolder text-secondary">Donate to our Temple</h3>
                <Pageheader />
                <p className="lead text-justify">Please use Secure Donate button below to make donations to Temple online.
                 Your donations are secure and you can use Credit/Debit/ATM or Direct Debit for donations</p>
                <MDBRow>
                    <MDBCol md="12" className="mx-auto">
                        <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
                            <input type="hidden" name="cmd" value="_s-xclick" />
                            <input type="hidden" name="hosted_button_id" value="RRMAMJJLD2XWA" />
                            <input type="image" src="https://iowaht.s3-us-east-2.amazonaws.com/images/donate2.png" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" style={{ margin: "0 auto", display: "block", maxWidth: "300px" }} />
                            <img alt="" border="0" src="https://www.paypal.com/en_US/i/scr/pixel.gif" width="1" height="1" />
                        </form>
                    </MDBCol>
                </MDBRow>
                <p className="lead text-justify">Thanks for your generous donation. Your donation may be tax deductible as allowed by IRS. You will get an year end donation receipt in the mail showing all your donations made to this Temple in this fiscal year. Please contact Temple EC members if you have any questions or feedback.</p>

            </MDBContainer>

        </>
    );
};

export default DonatePage;